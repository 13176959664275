body {
  background-color: #f5f5f5 !important;
}
button {
  cursor: pointer !important;
  padding-left: 5px;
  padding-right: 5px;
}

.table-block {
  width: 100%;
  overflow-x: hidden;
  white-space: nowrap;
}

.table-block.interest-panel {
  margin-top: 40px;
}

.artgapi-resposive table td {
  font-size: 16px !important;
}

/* Header and Drawer */

.drawer-wrapper .artgapi-header-wrapper {
  width: 100%;
  margin-left: 0;
}

.drawer-wrapper .artgapi-header-wrapper {
  width: 100%;
  border-bottom: 2px solid #ffcc33;
}

.data-wrapper {
  display: flex;
  justify-content: center;
  /* overflow-x: hidden; */
}

.drawer-wrapper {
  width: 260px;
}

.dashboard-wrapper {
  width: calc(98% - 260px);
  position: relative;
  margin-left: 1%;
  margin-right: 1%;
  height: 100vh;
}

.artgapi-drawer .MuiPaper-elevation {
  margin-top: 66px;
}

.artgapi-drawer .MuiPaper-root {
  background-color: #141415;
}

.logoBox {
  background: #141415;
  text-align: left;
  padding: 0;
  display: inline-block;
}

.logoBox img {
  vertical-align: middle;
}

.nav-items a:hover {
  background-color: transparent;
}

.nav-items .css-bgkkbm-MuiButtonBase-root-MuiListItemButton-root:hover {
  background-color: transparent;
}

.nav-items li .MuiListItemButton-root {
  padding: 0;
}

.artgapi-divider {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 64px);
}

.artgapi-drawer .nav-items.artgapi-lst-items {
  background-color: #141415;
  padding-top: 0;
  padding-bottom: 0;
  height: 100%;
  overflow-y: scroll;
}

.nav-items li a.level-zero > span {
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.artgapi-drawer .nav-items.artgapi-lst-items .nav-items {
  padding-left: 20px;
}

.nav-items li {
  padding: 0;
}

.app_container main {
  padding-top: 0;
  padding-bottom: 0;
}

.nav-items li .borders-box {
  border-bottom: 1px solid #463f3f;
  padding-top: 12px;
  padding-bottom: 12px;
  width: 98%;
  padding: 12px 0 12px 0;
  margin-left: 5%;
  margin-right: 0;
}

.nav-items li a {
  padding: 0;
  color: #fff;
  font-size: 16px;
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
  align-items: center;
}

.nav-items li .borders-box.active a svg path,
.nav-items li .borders-box.subitem-active a svg path,
.nav-items li a:hover svg path {
  fill: #ffcc33;
}

.nav-items li .normal-text.text-active,
.nav-items li .submenu-normal-text.submenu-text-active,
.nav-items li .borders-box.subitem-active .submenu-normal-text,
.nav-items li a:hover {
  color: #ffcc33;
}

.nav-items li a svg {
  margin-right: 5px;
}

.nav-items li a.level-zero span.MuiBadge-root {
  width: 100%;
  flex-direction: row;
}

.nav-items li a.level-one span.MuiBadge-root {
  width: 100%;
  flex-direction: column;
}

.nav-items li a .MuiBadge-badge {
  right: 50px;
  top: 17px;
  width: 30px;
  height: 30px;
  font-size: 10px !important;
}

.nav-items li a .MuiCollapse-root .MuiTypography-root {
  font-size: 14px;
}

.nav-items li a span.BaseBadge-root .MuiCollapse-root {
  padding-left: 20px;
}

.artgapi-user-logo {
  margin-left: auto;
}

.drawer-wrapper main {
  padding: 0;
}

.drawer-wrapper .button-logout {
  color: #0d0d0d;
  bottom: 0;
  background: #ffcc33;
  padding: 12px;
  width: 240px;
  vertical-align: middle;
  border-radius: 0;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-top: auto;
  text-transform: capitalize;
}

.drawer-wrapper .button-logout:hover {
  background: #ffcc33;
}

.artgapi-filterbox-searchbar-box .MuiSelect-outlined {
  text-align: left;
}

/* custom scrollbar */
.artgapi-drawer .nav-items::-webkit-scrollbar {
  width: 14px;
}

.artgapi-drawer .nav-items::-webkit-scrollbar-track {
  background-color: transparent;
}

.artgapi-drawer .nav-items::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 14px;
  border: 6px solid transparent;
  background-clip: content-box;
}

.artgapi-drawer .nav-items::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

/* Dashboard */

.dashboard-wrapper .artgapi-dashboard-wrappper {
  align-content: flex-start;
  justify-content: flex-start;
  padding: 72px 24px;
  margin-top: 3%;
}

.data-wrapper .dashboard-wrapper > span {
  position: static;
  margin-top: 85px;
  display: inline-block;
}

.artgapi-weekly-block-wrapper {
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.App .MuiContainer-root {
  max-width: 100%;
}

.artgapi-dashboard-wrappper .artgapi-dashboard-title {
  margin-top: 0;
  margin-bottom: 2%;
}

.data-wrapper .MuiBox-root {
  padding-left: 0;
  padding-right: 0;
}

.artgapi-save {
  padding: 16px 20px;
}

.artgapi-chart-text-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  padding: 20px 16px;
  background-color: #fff;
  width: 100%;
  justify-content: space-between;
}

.artgapi-chart-text {
  width: 100%;
}

.data-wrapper .dashboard-wrapper .change-password-btn {
  align-items: flex-end;
}

.artgapi-profile label {
  font-size: 1rem;
  overflow: visible;
  line-height: normal;
  height: auto;
}

.artgapi-filterbox-searchbar-wrapper {
  text-align: right;
}

.artgapi-filterbox-searchbar-box .artgapi-btn-holder {
  border-radius: 0;
}

.artgapi-btn-holder .MuiSelect-outlined {
  padding-top: 11.5px;
  padding-bottom: 11.5px;
}

.artgapi-filterbox-searchbar-box {
  display: flex;
  gap: 30px;
  margin-top: 30px;
  flex-direction: column;
  align-items: flex-start;
}

.dashboard-wrapper .artgapi-system-admin {
  margin-bottom: 30px;
}

.dashboard-wrapper .artgapi-user-email-wrapper {
  padding-bottom: 0;
}

.artgapi-user-email-wrapper .artgapi-user-email-lists:last-child {
  padding-bottom: 0;
}

.artgapi-user-email-wrapper .artgapi-user-email-lists span {
  font-size: 14px;
}

.dashboard-wrapper .artgapi-upper-fix {
  padding-top: 175px;
}

/* forgot-password */
.App .MuiContainer-root.artgapi-forgot-password {
  max-width: 444px;
}
/* modal */
.artgapi-wrapper {
  font-family: 'SFPro-Regular';
}
.artgapi-modal p,
.artgapi-modal p font > font {
  font-weight: bold;
  font-family: 'SFPro-Regular';
}
.artgapi-description p {
  font-weight: normal;
}

.artgapi-modal label,
.artgapi-modal label font > font {
  color: #000 !important;
  font-family: 'SFPro-Regular';
  overflow: visible;
  font-size: 16px;
  font-family: 'SFPro-Regular';
}

.artgapi-modal h6 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  font-family: 'SFPro-Regular';
}
.artgapi-wrapper h5 {
  color: #000;
  align-self: center;
  font-weight: 600;
  font-family: 'SFPro-Regular';
  padding-left: 8px;
}

.artgapi-plan-form label,
.artgapi-plan-form label font > font {
  line-height: normal;
  display: inline-block;
  color: #000;
  margin-bottom: 5px;
  overflow: visible;
  font-family: 'SFPro-Regular';
  font-size: 14px;
  height: auto;
  line-height: normal;
}

.artgapi-upload-btn,
.artgapi-upload-btn font > font {
  margin-bottom: 2px !important;
}
.category-langage-label {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.country-add-btn {
  width: 15%;
  height: 48px;
  border-radius: 5px !important;
  cursor: pointer;
  background-color: #1976d2 !important;
  color: #fff;
}
.country-box {
  padding-left: 0;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.country-wrapper {
  display: flex;
  gap: 5px;
  border: 1px solid #141415;
  border-radius: 0;
  margin-bottom: 5px;
  align-items: center;
}

.country-label {
  color: #141415;
  font-weight: bold;
  font-size: 16px;
}

.country-title {
  color: #141415;
  font-weight: 400;
  font-size: 14px;
}

.country-container {
  display: flex;
  padding: 10px;
  gap: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.delete-country-label button {
  color: #141415;
}

.country-dropdown {
  width: 40%;
  margin-top: 0 !important;
  margin-bottom: -12px !important;
}

.select-country {
  width: 100% !important;
}

.select-country > div {
  padding-bottom: 27px;
}

.no-image-found {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hide-text-overflow {
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.classroom-order {
  order: 1;
}

.error-learnpoint-label {
  align-items: flex-start !important;
}
.error-learnpoint-label .MuiInputLabel-root {
  margin-bottom: 0 !important;
}
.learnpoint-label .MuiInputLabel-root {
  margin-bottom: 0 !important;
}
.learnpoint-label {
  align-items: flex-start !important;
}
/* Responsive */

@media screen and (min-width: 320px) {
  #artgapi-dashboard-chart-left {
    flex-basis: 100%;
    max-width: 100%;
    margin-bottom: 2%;
  }

  #artgapi-dashboard-chart-right {
    flex-basis: 100%;
    max-width: 100%;
  }
}

@media screen and (min-width: 1099px) {
  .artgapi-export-text {
    margin-left: 88%;
  }
}

@media screen and (min-width: 1179px) {
  .artgapi-chart-text-wrapper {
    flex-direction: row;
    align-items: center;
  }

  .artgapi-chart-text {
    width: 50%;
  }

  .artgapi-filterbox-searchbar-box {
    display: flex;
    gap: 30px;
    margin-top: 30px;
    flex-direction: row;
    align-items: flex-start;
  }
}

@media screen and (min-width: 1299px) {
  .artgapi-export-text {
    margin-left: 90%;
  }

  .dashboard-wrapper .artgapi-upper-fix {
    padding-top: 175px;
  }
}

@media screen and (min-width: 1599px) {
  .artgapi-export-text {
    margin-left: 93%;
  }

  .dashboard-wrapper .artgapi-upper-fix {
    padding-top: 88px;
  }
}
.disabled-btn {
  background-color: #b1adad !important;
}

.lightbox-no-navigation .yarl__button_prev,
.lightbox-no-navigation .yarl__button_next {
  display: none !important;
}
