.App {
  background-color: #f5f5f5;
}
@font-face {
  font-family: 'SFPro-Regular';
  src: local('SFPro-Regular'), url(../src/assets/fonts/SF-Pro-Regular.ttf) format('truetype');
}
body {
  overflow: 'hidden';
}
