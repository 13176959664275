.custom-tab-scroll .MuiTabs-flexContainer::-webkit-scrollbar {
  height: 6px;
  width: 100%;
  background: gray;
}
/* Track */
.custom-tab-scroll .MuiTabs-flexContainer::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
.custom-tab-scroll .MuiTabs-flexContainer::-webkit-scrollbar-thumb {
  background: #888;
}
/* Handle on hover */
.custom-tab-scroll .MuiTabs-flexContainer::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.custom-tab-scroll .MuiTabs-flexContainer::-webkit-scrollbar-thumb:horizontal {
  background: #ffcc33;
  border-radius: 10px;
}

.custom-tab-scroll .MuiButtonBase-root {
  flex-shrink: 1;
  margin: 0 auto;
  max-width: none;
  min-width: auto;
}

.custom-tab-scroll a {
  margin: 0 auto;
}

.custom-tab-scroll span.MuiTabs-indicator {
  display: none;
}

.custom-tab-scroll a span.BaseBadge-badge {
  right: 2px !important;
}
