.central {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 80%;
  max-width: 500px;
  transform: translate(-50%, -50%);
  box-sizing: border-box !important;
}

.central h1 {
  color: white;
  text-align: center;
}


#recoveryPass {
  width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1em;
  padding-top: 0.7em;
  padding-bottom: 0.7em;
  font-size: 18px;
  border-radius: 5px;
  border: 2px solid white;
  outline: none;
  box-sizing: border-box !important;
  font-style: 'Raleway';
  background-color: #272727;
  color: white;
}

#recoveryButton {
  margin-top: 1em;
  padding-left: 2em;
  padding-right: 2em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  font-size: 18px;
  border-radius: 5px;
  background-color: #daa520;
  border: 2px solid white;
  color: white;
  transition: 0.5s;
}

#recoveryButton:hover {
  cursor: pointer;
  transition: 0.5s;
}

/* CHECK */
/* CSS ICON ALERT GENERAL */

.screenAlert-icon {
  border-radius: 50%;
  border: 4px solid gray;
  box-sizing: content-box;
  height: 80px;
  margin: 20px auto;
  padding: 0;
  position: relative;
  width: 80px
}

.screenAlert-icon+.screenAlert-icon {
  margin-top: 50px
}

/* CSS SUCCESS */
.screenAlert-icon.screenAlert-success {
  border-color: #A5DC86
}

.screenAlert-icon.screenAlert-success:after,
.screenAlert-icon.screenAlert-success:before {
  background-color: transparent !important;
  content: '';
  height: 120px;
  position: absolute;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 60px
}

.screenAlert-icon.screenAlert-success:before {
  border-radius: 120px 0 0 120px;
  left: -33px;
  top: -7px;
  -webkit-transform-origin: 60px 60px;
  transform-origin: 60px 60px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg)
}

.screenAlert-icon.screenAlert-success:after {
  border-radius: 0 120px 120px 0;
  left: 30px;
  top: -11px;
  -webkit-transform-origin: 0 60px;
  transform-origin: 0 60px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg)
}

.screenAlert-icon.screenAlert-success .screenAlert-placeholder {
  border-radius: 50%;
  border: 4px solid rgba(165, 220, 134, 0.2);
  box-sizing: content-box;
  height: 80px;
  left: -4px;
  position: absolute;
  top: -4px;
  width: 80px;
  z-index: 2
}

.screenAlert-icon.screenAlert-success .screenAlert-line {
  background-color: #A5DC86;
  border-radius: 2px;
  display: block;
  height: 5px;
  position: absolute;
  z-index: 2
}

.screenAlert-icon.screenAlert-success .screenAlert-line.screenAlert-tip {
  left: 14px;
  top: 46px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 25px
}

.screenAlert-icon.screenAlert-success .screenAlert-line.screenAlert-long {
  right: 8px;
  top: 38px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  width: 47px
}

.animateSuccessTip {
  -webkit-animation: animateSuccessTip .75s;
  animation: animateSuccessTip .75s
}

.animateSuccessLong {
  -webkit-animation: animateSuccessLong .75s;
  animation: animateSuccessLong .75s
}

/* ANIMATE SUCCESS */

@-webkit-keyframes animateSuccessTip {

  0%,
  54% {
    width: 0;
    left: 1px;
    top: 19px
  }

  70% {
    width: 50px;
    left: -8px;
    top: 37px
  }

  84% {
    width: 17px;
    left: 21px;
    top: 48px
  }

  100% {
    width: 25px;
    left: 14px;
    top: 45px
  }
}

@keyframes animateSuccessTip {

  0%,
  54% {
    width: 0;
    left: 1px;
    top: 19px
  }

  70% {
    width: 50px;
    left: -8px;
    top: 37px
  }

  84% {
    width: 17px;
    left: 21px;
    top: 48px
  }

  100% {
    width: 25px;
    left: 14px;
    top: 45px
  }
}

@-webkit-keyframes animateSuccessLong {

  0%,
  65% {
    width: 0;
    right: 46px;
    top: 54px
  }

  84% {
    width: 55px;
    right: 0;
    top: 35px
  }

  100% {
    width: 47px;
    right: 8px;
    top: 38px
  }
}

@keyframes animateSuccessLong {

  0%,
  65% {
    width: 0;
    right: 46px;
    top: 54px
  }

  84% {
    width: 55px;
    right: 0;
    top: 35px
  }

  100% {
    width: 47px;
    right: 8px;
    top: 38px
  }
}

/* CSS ALERT */
.screenAlert-icon.screenAlert-error {
  border-color: #F27474
}

.screenAlert-icon.screenAlert-error:after,
.screenAlert-icon.screenAlert-error:before {
  background-color: transparent !important;
  content: '';
  height: 120px;
  position: absolute;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 60px
}

.screenAlert-icon.screenAlert-error:before {
  border-radius: 120px 0 0 120px;
  left: -33px;
  top: -7px;
  -webkit-transform-origin: 60px 60px;
  transform-origin: 60px 60px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg)
}

.screenAlert-icon.screenAlert-error:after {
  border-radius: 0 120px 120px 0;
  left: 30px;
  top: -11px;
  -webkit-transform-origin: 0 60px;
  transform-origin: 0 60px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg)
}

.screenAlert-icon.screenAlert-error .screenAlert-placeholder {
  border-radius: 50%;
  border: 4px solid rgba(165, 220, 134, 0.2);
  box-sizing: content-box;
  height: 80px;
  left: -4px;
  position: absolute;
  top: -4px;
  width: 80px;
  z-index: 2
}

.screenAlert-icon.screenAlert-error .screenAlert-line {
  background-color: #A5DC86;
  border-radius: 2px;
  display: block;
  height: 5px;
  position: absolute;
  z-index: 2
}

.screenAlert-icon.screenAlert-error .screenAlert-line.screenAlert-tip {
  left: 14px;
  top: 46px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 25px
}

.screenAlert-icon.screenAlert-error .screenAlert-line.screenAlert-long {
  right: 8px;
  top: 38px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  width: 47px
}

.screenAlert-icon.screenAlert-error .screenAlert-x-mark {
  display: block;
  position: relative;
  z-index: 2
}

.screenAlert-icon.screenAlert-error .screenAlert-placeholder {
  border: 4px solid rgba(200, 0, 0, 0.2)
}

.screenAlert-icon.screenAlert-error .screenAlert-line {
  background-color: #F27474;
  top: 37px;
  width: 47px
}

.screenAlert-icon.screenAlert-error .screenAlert-line.screenAlert-left {
  left: 17px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg)
}

.screenAlert-icon.screenAlert-error .screenAlert-line.screenAlert-right {
  right: 16px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg)
}

.animateXLeft {
  -webkit-animation: animateXLeft .75s;
  animation: animateXLeft .75s
}

.animateXRight {
  -webkit-animation: animateXRight .75s;
  animation: animateXRight .75s
}

/* ANIMATE CROSS X */

@-webkit-keyframes animateXLeft {

  0%,
  65% {
    left: 82px;
    top: 95px;
    width: 0
  }

  84% {
    left: 14px;
    top: 33px;
    width: 47px
  }

  100% {
    left: 17px;
    top: 37px;
    width: 47px
  }
}

@keyframes animateXLeft {

  0%,
  65% {
    left: 82px;
    top: 95px;
    width: 0
  }

  84% {
    left: 14px;
    top: 33px;
    width: 47px
  }

  100% {
    left: 17px;
    top: 37px;
    width: 47px
  }
}

@-webkit-keyframes animateXRight {

  0%,
  65% {
    right: 82px;
    top: 95px;
    width: 0
  }

  84% {
    right: 14px;
    top: 33px;
    width: 47px
  }

  100% {
    right: 16px;
    top: 37px;
    width: 47px
  }
}

@keyframes animateXRight {

  0%,
  65% {
    right: 82px;
    top: 95px;
    width: 0
  }

  84% {
    right: 14px;
    top: 33px;
    width: 47px
  }

  100% {
    right: 16px;
    top: 37px;
    width: 47px
  }
}

/* CSS WARNING */
.screenAlert-icon.screenAlert-warning {
  border-color: #F8BB86
}

.screenAlert-icon.screenAlert-warning:before {
  -webkit-animation: pulseWarning 2s linear infinite;
  animation: pulseWarning 2s linear infinite;
  background-color: transparent !important;
  border-radius: 50%;
  content: "";
  display: inline-block;
  height: 100%;
  opacity: 0;
  position: absolute;
  width: 100%
}

.screenAlert-icon.screenAlert-warning:after {
  background-color: transparent !important;
  border-radius: 50%;
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 1
}

.screenAlert-icon.screenAlert-warning .screenAlert-body {
  background-color: #F8BB86;
  border-radius: 2px;
  height: 47px;
  left: 50%;
  margin-left: -2px;
  position: absolute;
  top: 10px;
  width: 5px;
  z-index: 2
}

.screenAlert-icon.screenAlert-warning .screenAlert-dot {
  background-color: #F8BB86;
  border-radius: 50%;
  bottom: 10px;
  height: 7px;
  left: 50%;
  margin-left: -3px;
  position: absolute;
  width: 7px;
  z-index: 2
}

.scaleWarning {
  -webkit-animation: scaleWarning .75s infinite alternate;
  animation: scaleWarning .75s infinite alternate
}

.pulseWarningIns {
  -webkit-animation: pulseWarningIns .75s infinite alternate;
  animation: pulseWarningIns .75s infinite alternate
}

/* ANIMATE WARNING */

@-webkit-keyframes scaleWarning {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }

  30% {
    -webkit-transform: scale(1.02);
    transform: scale(1.02)
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }
}

@keyframes scaleWarning {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }

  30% {
    -webkit-transform: scale(1.02);
    transform: scale(1.02)
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }
}

@-webkit-keyframes pulseWarning {
  0% {
    background-color: #fff;
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: .5
  }

  30% {
    background-color: #fff;
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: .5
  }

  100% {
    background-color: #F8BB86;
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 0
  }
}

@keyframes pulseWarning {
  0% {
    background-color: #fff;
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: .5
  }

  30% {
    background-color: #fff;
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: .5
  }

  100% {
    background-color: #F8BB86;
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 0
  }
}

@-webkit-keyframes pulseWarningIns {
  0% {
    background-color: #F8D486
  }

  100% {
    background-color: #F8BB86
  }
}

@keyframes pulseWarningIns {
  0% {
    background-color: #F8D486
  }

  100% {
    background-color: #F8BB86
  }
}