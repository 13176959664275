.isRealsed {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffcc33;
  height: 100%;
  width: 136px;
  font-family: SFPro-Regular;
  font-size: 17px;
  color: #0d0d0d;
  border: none;
}
.isActive {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 136px;
  font-family: SFPro-Regular;
  font-size: 17px;
  color: #0d0d0d;
  background-color: #1ab53c;
  border: none;
}
