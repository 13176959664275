.tox-tinymce {
  margin-top: 10px;
  border-radius: 0px !important;
} 

.tox .tox-editor-container{
  row-gap: 10px;
}
.tox-tbtn--bespoke {
  width: 20px !important;
  background-color: white !important;
}